* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}

a {
  font-weight: initial;
  text-decoration: none;
}

html,
body {
  width: 100%;
  height: 100%;
  font-feature-settings: "tnum" 1, "calt" 0;
  font-variant-numeric: tabular-nums;
}

button,
input,
textarea,
select {
  background: transparent;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  white-space: pre;
  font-feature-settings: "tnum" 1, "calt" 0;
  font-variant-numeric: tabular-nums;
}

button {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

body {
  font-family: Pretendard, sans-serif;
  color: var(--text);
  word-break: keep-all;
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  min-height: 100vh;
}

#root {
  height: 100%;
}
.wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

// icon
.material-symbols-rounded {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}