.content {
  height: 100%;
  margin-top: var(--header-height);
  background-color: var(--bg);
  .content_wrap {
    display: grid;
    gap: 30px;
    max-width: 600px;
    margin: 0 auto;
    padding: 0 20px;
    @media screen and (max-width: $mobile) {
      gap: 20px;
    }
  }
} 

.page_title {
  font-size: 42px;
  font-weight: 600;
  line-height: 1.2;
  color: var(--text);
  margin-top: 54px;
  @media screen and (max-width: $mobile) {
    font-size: 32px;
  }
}

.card {
  display: grid;
  gap: 30px;
  padding: 28px;
  background-color: var(--card-bg);
  border-radius: 16px;
  box-shadow: 0 1px 1px 0 hsl(0 0% 0% / 0.03);
  .card_header {
    display: grid;
    gap: 8px;
  }
  .card_title {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2;
    color: var(--text);
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: var(--text-muted);
  }
}

.select_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  border: 1px solid var(--button-primary-bg);
  border-radius: 12px;
  font-size: 14px;
  color: var(--button-primary-bg);
  i {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    user-select: none;
    z-index: 0;
  }
  .select {
    position: relative;
    appearance: none;
    height: 100%;
    padding: 0 40px 0 16px;
    z-index: 1;
  }
}


.form {
  display: grid;
  gap: 28px;
}

.input_container {
  display: grid;
  gap: 8px;
  width: 100%;
  .input {
    width: 100%;
    height: 48px;
    border-radius: 12px;
    padding: 0 20px;
    border: 1px solid var(--card-border);
    color: var(--text);
    transition: border 300ms linear;
    &:focus {
      border-color: var(--text);
    }
    &::placeholder {
      color: var(--text-disable);
    }
  }
}

.form_label {
  font-size: 14px;
  font-weight: 500;
  color: var(--text);  
}

.form_message {
  font-size: 13px;
  font-weight: 400;
  color: var(--danger);
}

.button_container {
  display: flex;
  align-items: center;
  gap: 12px;
  @media screen and (max-width: $mobile-sm) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.button, a.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 48px;
  border-radius: 12px;
  padding: 0 20px;
  transition: filter 300ms linear;
  &:hover {
    filter: contrast(88%);
  }
  &.primary {
    background-color: var(--button-primary-bg);
    color: var(--button-primary-text);
  }
  &.default {
    background-color: var(--button-default-bg);
    color: var(--button-default-text);
  }
}

.state_tag {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 13px;
  font-weight: 500;
  i {
    font-size: 18px;
  }
  &.success {
    color: var(--success);
  }
  &.failed {
    color: var(--danger);
  }
  &.inProcess {
    color: var(--text-muted);
  }
}

.Toastify__toast-container--top-right {
  top: 15px !important;
  right: 15px !important;
  width: 360px !important;
}

.Toastify__toast {
  word-break: break-word !important;
  font-family: inherit !important;
  padding: 15px !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  font-weight: 500;
  letter-spacing: 0 !important;
  line-height: 1.5 !important;
}

.Toastify__toast--info {
  background: #00b1ff !important;
}
.Toastify__toast--success {
  background: #17CFAA !important;
}
.Toastify__toast--warning {
  background: #FC9803 !important;
}
.Toastify__toast--error {
  background: #FF335F !important;
}