$mobile-sm: 320px;
$mobile: 500px;
$tablet: 1023px;

:root {
  --header-height: 64px;
  --max-width: 1600px;
  --primary-color: #00b1ff;
  --menu-bg: #031435;
  --menu-text: hsl(0, 0%, 100%);
  --bg: #F6F6F9;
  --text: #16161A;
  --text-muted: rgba(22, 22, 26, 0.6);
  --text-disable: rgba(22, 22, 26, 0.4);
  --card-bg: #fff;
  --card-border: rgba(16, 18, 20, 0.08); 
  --button-default-bg:#EDEDF3;
  --button-default-text: #40464F;
  --button-primary-bg: #00b1ff;
  --button-primary-text: #fff;
  --link: #66B3FF;
  
  --info: #00b1ff;
  --info-light: rgba(0, 177, 255, 0.1);
  --danger: #FF335F;
  --danger-light:rgba(255, 51, 95, 0.1);
  --success: #17CFAA;
  --success-light: rgba(23, 207, 170, 0.1);
  --warning: #FC9803;
  --warning-light:rgba(252, 152, 3, 0.1);

  @media screen and (max-width: $tablet) {

  }
  @media screen and (max-width: $mobile) {

  }
}

//z-index
$z-index: (
  bg: 1,
  content: 2,
  header: 3,
  nav: 4,
  navContent: 5,
  modalBg: 6,
  modal: 7,
);
