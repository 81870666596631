.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: var(--header-height);
  background-color: var(--menu-bg);
  padding: 0 20px;
  z-index: map-get($z-index, header);
  .headerWrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    margin: 0 auto;
  }
}


.logo {
  display: flex;
  img {
    height: 32px;
  }
  @media screen and (max-width: $mobile) {
    img {
      height: 28px;
    }
  }
  @media screen and (max-width: $mobile-sm) {
    img {
      height: 24px;
    }
  }
}
